import React, { useContext, useEffect } from 'react';
import { ConfigContext, AuthTokenContext } from '../../context';
import LoginForm from './Forms/LoginForm';
import OTPForm from './Forms/OTPForm';
import PasswordResetForm from './Forms/PasswordResetForm';
import RegisterForm from './Forms/RegisterForm';
import SuccessForm from './Forms/SuccessForm';
import styles from './Login.module.scss';

const form = (location) => {
  switch (location.pathname) {
    case '/login':
      const search = new URLSearchParams(location.search);
      return <LoginForm pwResetStatus={search.get('password-reset')} />;
    case '/password-reset':
      return <PasswordResetForm />;
    case '/register':
      return <RegisterForm />;
    case '/register/success':
      return <SuccessForm />;
    case '/password-reset/success':
      return <SuccessForm />;
    case '/otp-validate':
      return <OTPForm />;
    default:
      return null;
  }
};

const Login = ({ location, history }) => {
  const { config } = useContext(ConfigContext);
  const { isTokenValid } = useContext(AuthTokenContext);
  
  const logoSrc = config?.country_image_white || null;
  
  useEffect(() => {
    if (isTokenValid()) {
      history.push('/');
    }
  }, [isTokenValid, history]);

  return (
    <div>
      <div className={styles.LogoContainer}>
        <img src={`data:image/png;base64,${logoSrc}`} alt="GoodX Client Portal" />
        <React.Fragment>
          {location.pathname === '/login' ? <h1>Client Portal<br></br>Login</h1> : null}
          {location.pathname === '/otp-validate' ? <h1>Validate OTP</h1> : null}
          {location.pathname === '/register' ? <h1>Register Account</h1> : null}
          {location.pathname === '/register/success' ? <h1>Register Account</h1> : null}
          {location.pathname === '/password-reset' ? <h1>Reset Password</h1> : null}
          {location.pathname === '/password-reset/success' ? <h1>Reset Password</h1> : null}
        </React.Fragment>
      </div>

      <div className={styles.FormWrapper}>
        {form(location)}
      </div>
    </div>
  );
}

export default Login;
